import React, { Component } from 'react';
import '../assets/css/about.css'

export default class About extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-md-4">
                    <div className="img-container">
                        <img src={require('../assets/images/sweta_about.jpg')} />
                    </div>
                </div>
                <div className="col-md-8">
                    <div className="about-desc">
                        <p>
                            I am passionate and self-taught full-stack web developer having 4+ years of work experience. I have started my career as a software trainee in 2017 just after my graduation completed.
                            Initially, I was interested in frontend development because I like to develop what users interact with, later I also started working in backend development and started implementing features from scratch.
                            In my earlier experience, I was part of different projects with different teams and successfully released it.
                            Regularly I used to learn something new to keep up to date myself with the latest technologies and increase my knowledge in this field.
                        </p>
                        <p>
                            My colleagues always says that I am a very honest and helpful person. I always prefer to help others if they stuck at any point or help needed,
                            My strongest asset is my work ethic and my willingness to step in when needed. I take great pride in my ability to step in and adapt to any situation to get the best results for the company.
                        </p>
                        <p>
                            Apart from technical skills I like drawing and crafting which helps me to reduce my stress and increase my creativity, I usually draw in my spare time it makes me feel happy :)
                            I also like to hang out on weekends with friends and family. I am foodie and also like to cook new tasty dishes for me and my family :D
                        </p>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="my-skills">
                        <h2>My Skills</h2>
                        <div className="skills">
                            <ul>
                                <li>
                                    <label>FrontEnd:</label>
                                    <span>Angular2+, ReactJs, TypeScript, JavaScript, jQuery, ES6, HTML5/CSS3, Bootstrap, Bulma, Angular Material, Responsive web pages</span>
                                </li>
                                <li>
                                    <label>BackEnd:</label>
                                    <span>Node.js, Express.js, PostgreSQL, MongoDB, Firebase, Passport.js, Sequelize, Handlebars.js</span>
                                </li>
                                <li>
                                    <label>Other:</label>
                                    <span className="other_skill">Amazon Web Services(AWS), Stripe, NGINX, Facebook API, Google API, Git, Jeera, Trello</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}