import React, { Component } from 'react';
import '../assets/css/footer.css'

export default class Footer extends Component {
    render() {
        return (
            <div className="row">
                <footer className="footer">
                    <span>
                        © Copyright 2022 Sweta Unagar
                    </span>
                </footer>
            </div>
        )
    }
}