import React, { Component } from 'react';
import '../assets/css/home.css'

export default class Home extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-md-2">
                    <div className="img-container">
                        <img src={require('../assets/images/sweta_home.png')} />
                    </div>
                </div>
                <div className="col-md-10">
                    <h1>Hi I'm Sweta</h1>
                    <p>A full stack web developer based in Surat, India. I am available for new opportunities.</p>
                    <div className="social_link">
                        <a target="_blank" href="https://twitter.com/SwetaUnagar"><i className="fab fa-twitter"></i></a>
                        <a target="_blank" href="https://stackoverflow.com/users/11284301/sweta-unagar?tab=profile"><i className="fab fa-stack-overflow"></i></a>
                        <a target="_blank" href="https://dribbble.com/swetaunagar"><i className="fab fa-dribbble"></i></a>
                        <a target="_blank" href="mailto:swetaunagar@gmail.com"><i className="fa fa-envelope"></i></a>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="project">
                        <h2>Projects</h2>

                        <div className="project-desc">
                            <div>
                                <div>
                                    <label><a href="https://bookingdemo.zielcommerce.in/" target="_blank">Your Best Hair</a></label>
                                </div>
                            </div>
                            <div className="project-detail">
                                <p>
                                    It’s a service marketplace to book hair services online. 
                                    Users can search nearby hairstylists or based on categories and then choose the datetime, do payment and confirm the service appointment. 
                                    User can see their booked service status and can give ratings and reviews to the hairstylist.
                                </p>
                                <p>
                                    This project includes three panels, a customer panel for end-users, an admin panel for admin, and a vendor panel for the hairstylist to manage their stuff. 
                                    Initially, I was provided with the requirements doc and the customer panel’s HTML screens. 
                                    I started from schema design, API developments, admin/vendor panel UI design, payment gateway integration, API integrations across three panels, 
                                    and set up everything in a virtual machine to enable the dev environment and access everything live.
                                </p>
                            </div>
                        </div>

                        <div className="project-desc">
                            <div>
                                <div>
                                    <label><a href="https://www.lawyaw.com/" target="_blank">Lawyaw</a></label>
                                </div>
                            </div>
                            <div className="project-detail">
                                <p>
                                    I developed a demo application for their internal purpose to demonstrate stripe payment handling for different types of products as per their requirements.
                                </p>
                            </div>
                        </div>

                        <div className="project-desc">
                            <div>
                                <div>
                                    <label><a href="https://retainer.dev" target="_blank">Retainer</a></label>
                                </div>
                            </div>
                            <div className="project-detail">
                                <p>
                                    It's a platform to get automatic payments from clients with subscription-based recurring billing.
                                </p>
                                <p>
                                    I have developed this project from scratch. I was responsible for frontend and backend development as per the client's requirements.
                                    My responsibility includes creating APIs, Frontend integration, AWS(Storage S3 & Lambda Function), Stripe payment integration,
                                    Bug fixes/CSS fixes, and performance improvements. In this project, I needed to manage subscriptions and user handle profiles for
                                    login/logout flow and I successfully implemented these functionalities.
                                </p>
                            </div>
                        </div>

                        <div className="project-desc">
                            <div>
                                <div>
                                    <label><a href="https://unshack.com" target="_blank">Unshack</a></label>
                                </div>
                            </div>
                            <div className="project-detail">
                                <p>
                                    It's a platform to publish paid videos for a global audience.
                                    A video store just for creator's content. Sell content directly to the audience.
                                </p>
                                <p>
                                    I was part of the development team and responsible for backend as well as frontend development.
                                    I provided with UI and basic project structure. My responsibilities were to implement functionalities, implement APIs,
                                    Different routes of frontend and backend programming for those routes, Ajax call for getting and setting data to a server,
                                    Bug fixes, and UI fixes. I also integrated a Stripe payment gateway to handle payments.
                                </p>
                            </div>
                        </div>

                        <div className="project-desc">
                            <div>
                                <div>
                                    <label><a href="https://apps.apple.com/by/app/content-office-insta-manager/id1137376676?mt=8" target="_blank">Content Office</a></label>
                                </div>
                            </div>
                            <div className="project-detail">
                                <p>
                                    iOS planner for Instagram. It's Easy-to-use Instagram planner app.
                                    Visually plan Instagram feed, schedule posts & Stories in advance.
                                </p>
                                <p>
                                    I have been part of the development team of this project. The app was already developed,
                                    Mainly I did bug fixes in APIs and firebase cloud functions for user subscription flow.
                                    I have created postman collection for all the http cloud functions with basic test cases.
                                    For the admin panel, I did fixes for update subscription and also implemented end to end flow for find and update user functionalities.
                                </p>
                            </div>
                        </div>

                        <div className="project-desc">
                            <div>
                                <div>
                                    <label><a href="https://www.botanicalsense.com/" target="_blank">Leafit</a></label>
                                </div>
                            </div>
                            <div className="project-detail">
                                <p>
                                    It is an IoT based application basically about the health of plants.
                                    In this project, there is a sensor placed in a flower pot and connected with a home central gateway to communicate with the server using a mobile app
                                    and gives notifications to users about light, water and fertility need. So overall it helps to keep the plant happy and healthy.
                                </p>
                                <p>
                                    Here I have worked as an individual. I have established the server on AWS, developed the entire backend
                                    includes APIs consumed by mobile app and admin panel, and an end to end flow of admin panel where admin can view and manage all the data.
                                </p>
                            </div>
                        </div>

                        <div className="project-desc">
                            <div>
                                <div>
                                    <label><a href="https://www.scikey.ai" target="_blank">Scikey</a></label>
                                </div>
                            </div>
                            <div className="project-detail">
                                <p>It's a talent commerce platform for job seekers to find MindMatch jobs and recruiters to find MindMatch candidates.</p>
                                <p>
                                    I have started working with job creation flow,  It has multiple screens.
                                    Initially, I started this module as an individual and my responsibilities were database design, API requirements/development,
                                    frontend implementation, and also prepare the technical and functional document. Later we have completed it with a team of three members.
                                </p>
                                <p>
                                    After that, I have worked in the Fresume module which is basically a resume. Candidates can see and create their fresume.
                                    here I have worked in a team of three members, we have begun with the requirements gathering and database design and
                                    then I have developed APIs required for the initial functionalities.
                                </p>
                                <p>
                                    Then I was switched to the Candidate portal. In a team of three members, we were handling the backend as well as the frontend part of candidate sign in,
                                    sign up as a fresher and experienced, take an assessment, and manage profile flow.
                                    I was also responsible to collaborate with product manager and senior lead for requirements and deliver features before the deadline.
                                </p>

                                <p>
                                    Apart from the team as an individual, I have implemented configurable image advertisement functionality,
                                    based on config values it can be shown/hidden on any screen of the candidate portal, no need to change code.
                                </p>
                            </div>
                        </div>

                        <div className="project-desc">
                            <div>
                                <div>
                                    <label><a href="https://steerhigh.com/" target="_blank">SteerHigh</a></label>
                                </div>
                            </div>
                            <div className="project-detail">
                                <p>SteerHigh is a unique Employee Engagement platform that combines organization success with behavioural sciences.</p>
                                <p>
                                    I have started working on this project as a front-end developer. I was assigned with pride-board module which is a kind of dashboard
                                    where users can see their personal details, badges and awards, list of feedforwards, chart which shows how many users have filled
                                    their feedforward reports. For this, I needed to collaborate with the backend-developer for API requirements.
                                </p>
                                <p>
                                    Apart from this, I have customized the survey.js library to manage some views and add two custom question types as per the requirements,
                                    The questions were drag and drop cards from one container to another and both had different validations.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}