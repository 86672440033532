import Home from './home.js';
import About from './about.js';
import Resume from './resume.js'
import Header from './header.js';
import Footer from './footer.js'
import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import '../assets/css/header.css'

class AppRouting extends Component {
  render() {
    return (
      <Router>
        <div>
          <Header></Header>
        </div>
        <div className="content">
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/home">
              <Home />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/resume">
              <Resume />
            </Route>
          </Switch>
        </div>
        <div>
          <Footer></Footer>
        </div>
      </Router>
    );
  }
}

export default AppRouting;