import React, { Component } from 'react';
import '../assets/css/header.css'
import { Link } from "react-router-dom";
import { NavLink } from 'react-router-dom';
export default class Header extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-md-3 col-sm-12">
                    <div className="logo"><Link to="/home">Sweta Unagar</Link></div>
                </div>
                <div className="col-md-9 col-sm-12">
                    <div className="menu">
                        <div className="navbar navbar-nobg">
                            <div className="navbar-header">
                                <button className="btn-menu navbar-toggle" data-toggle="collapse" data-target="#navbar-menu">
                                    <i className="fas fa-bars"></i>
                                </button>
                            </div>
                            <div className="collapse navbar-collapse" aria-expanded="true" id="navbar-menu">
                                <ul className="nav navbar-nav navbar-right">
                                    <li><NavLink to="/home" activeClassName="active">Home</NavLink></li>
                                    <li><NavLink to="/about" activeClassName="active">About</NavLink></li>
                                    <li><NavLink to="/resume" activeClassName="active">Resume</NavLink></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}