import React, { Component } from 'react';
import '../assets/css/resume.css'

export default class Home extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <h2>Experience</h2>

                    <div className="experience">
                        <label>Full Stack Developer : Apr 2021 - Dec 2021</label><br />
                        <span>Bigziel, Chennai, India(Remote)</span>
                        <div className="work-desc">
                            <p>
                                Here I started work as an individual and handled the project from scratch. 
                                My responsibilities were to develop the Frontend, Backend and handle DevOps for the assigned project. 
                                I used to collaborate with the founding team members for all the requirements discussion and doubts. 
                                I also used to collaborate with UX designer and UI developer for HTML screens requirements and mobile app developer to hand over API details required for mobile app.
                            </p>
                            <p>
                                I was assigned with the requirements document. Initially, I was developing for only one project but later that project was converted into a product 
                                so it can be use for all relevant projects. So the approach was changed and the main focus was to make it customisable as much as possible. 
                                It was a service marketplace with a content management system and three panels customer portal, admin panel, and vendor panel.
                            </p>
                            <p>
                                I started with the schema design and then API developments. For backend development, I have used Node.js and MongoDB. 
                                For the frontend, I have used Angular 11 and the PrimeNG library(it has a rich set of input components). 
                                Along with this, I used a Stripe payment gateway to handle payments. I successfully set up Node.js and MongoDB in virtual machine 
                                and other configurations to enable the dev environment and access all three panels live.
                            </p>
                        </div>
                    </div>

                    <div className="experience">
                        <label>Consultant Software Developer : Jul 2020 - Jan 2021</label><br />
                        <span>Upcommune, Inc., San Francisco(Remote)</span>
                        <div className="work-desc">
                            <p>
                                I was part of the development team. My responsibilities were frontend and backend development of the product.
                            </p>
                            <p>
                                I directly collaborated with the client for requirements discussion. I worked with Node.js(Express) and MongoDB(mongoose) for the backend.
                                For the frontend, I used handlebars.js to render views and jQuery/Javascript to implement views functionalities.
                                I also worked with AWS S3 for image storage and lambda function for image compression.
                                Whenever any new image gets added to the source bucket, the lambda function compresses that image and puts it in the destination bucket.
                            </p>
                            <p>
                                The challenging part for me was to work with Stripe for payments management,
                                It was new for me because I didn't work with Stripe before but new things always excite me to work.
                                I have successfully integrated Stripe APIs for all payment related functionalities.
                            </p>
                        </div>
                    </div>

                    <div className="experience">
                        <label>Full Stack Developer : May 2019 - Jan 2020</label><br />
                        <span>Botanical Sense, Denmark(Remote) </span>
                        <div className="work-desc">
                            <p>
                                Here I have worked as an individual and directly collaborated with client for all requirements.
                                I was responsible for entire backend development, frontend development for web and DevOps part from scratch.
                            </p>
                            <p>
                                I have implemented restful APIs using Node.js(Express), Sequelize for ORM and Postgresql for database consumed by admin panel and mobile app. I have also
                                developed Admin panel from scratch using Angular8 and hosted both backend as well as admin panel using AWS and NGINX in which I have used EC2 instance, Route 53, RDS,
                                Certificate Manager, and S3 services.
                                I have added the authentication strategy for APIs using JWT and also used passport.js middleware for Facebook and google sign-up, sign-in.
                            </p>
                            <p>
                                I collaborated with mobile app developer and hardware developer for API requirements and if any data issues at the server-side.
                                I used to provide them API details using Postman API documentation.
                            </p>
                            <p>
                                The big challenge for me was to host a product website with the root domain and admin panel with the subdomain on AWS.
                                the product website was in squarespace.com so it has different configurations for the deployment and the admin panel has different.
                                Our domain provider was GoDaddy so the challenge was for me to connect and configure multiple things at the same place on AWS route 53 and I have successfully done these things :)
                                It was a challenge for me because I haven't deployed projects on AWS before.
                            </p>
                        </div>
                    </div>
                    <div className="experience">
                        <label>Associate Software Developer  : Dec 2017 – Feb 2019  </label><br />
                        <span>SRKay Consulting Group, Ichhapor, India</span>
                        <div className="work-desc">
                            <p>
                                After successful completion of training, I promoted with the "Associate Software Developer" position.
                                I was part of different projects with different teams. In a team, we collaborate and find out the desired solutions, estimate time, divide tasks and help each other to achieve goals before the deadline.
                            </p>
                            <p>
                                Initially, I started work in the frontend. I was provided with UI screens and asked to implement the functionalities and integrate APIs, for API requirements I collaborate with backend developer. I have implemented the frontend using Angular2/4. With frontend development, I was also curious to know about backend and develop Restful APIs so I started work in backend development using Node.js(Express.js), and MongoDB.
                                I assigned with the project module and I have to implement both frontend and backend, I used to make functional and technical documentation for the same module.
                            </p>
                            <p>
                                Because of my dedication towards the work, I became the micro team captain. As a team lead, my responsibilities were to collaborate directly with project manager and senior lead for requirements, distribute tasks among the team, help them if they stuck at any point, push them to work with best practice, review their code and deliver bug-free feature before the deadline. Apart from the micro team captain, I also have to manage my individual allocated tasks and deliver those before the deadline.
                            </p>
                            <p>
                                I have used Jeera tool for task tracking and was part of daily standup to gave daily updates of individual tasks and sometimes on behalf of the team. Because of my good and hard work I got the "Star of Sprint" badge two times.
                            </p>
                        </div>
                    </div>
                    <div className="experience">
                        <label>Associate Software Trainee : Jun 2017 – Dec 2017   </label><br />
                        <span>SRKay Consulting Group, Ichhapor, India</span>
                        <div className="work-desc">
                            <p>
                                I have completed 6 months of training including technical training in Angular2 and Javascript. Using this I made a small project and gain knowledge of how the project can be developed and managed.
                                During this training, I have completed many assignments as an individual and in a team. In a team, We have made one small project using Angular2,   Bootstrap, Node.js, and MongoDB in which I was the part of requirement gathering, mockups design, UI, frontend and backend API implementations.
                            </p>
                            <p>
                                Apart from this, I got a behavioral training in which I got a chance to improve my presentation and communication skills, Overcome stage fear and some other skill that helps me to work in an organization.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-md-12">
                    <h2>Education</h2>
                    <div className="edu-desc">
                        <label>Bachelor of Engineering(Information Technology) :  2014 - 2017 </label> <br />
                        <span>Vishwakarma Government Engineering College, Ahmedabad, India</span>
                    </div>
                    <div className="edu-desc">
                        <label>Diploma Engineering(Information Technology) :  2011 - 2014 </label><br />
                        <span>Government Polytechnic for Girls, Surat, India</span>
                    </div>
                </div>
            </div >
        )
    }
}
