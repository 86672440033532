import React, { Component } from 'react';
import './App.css';
import AppRouting from './components/routing.js'
import ReactGA from 'react-ga';
import { render } from '@testing-library/react';

class App extends Component {

  constructor() {
    super()
    ReactGA.initialize('UA-158519077-1');
    ReactGA.pageview('/');
    ReactGA.pageview('/home');
    ReactGA.pageview('/about');
    ReactGA.pageview('/resume');
  }

  render() {
    return (
      <div className="container">
        <AppRouting />
      </div >
    );
  }
}

export default App;
